<template>
    <el-dialog :title="title" :close-on-click-modal="false" :fullscreen="true" :visible.sync="visible"
               class="collectForm" v-if="visible">
        <div v-loading="loading" class="formBox">
            <el-form size="small" :inline="true" :model="inputForm" :rules="rules" ref="inputForm"
                     label-width="180px" :disabled="method==='view'" class="recordCollect">
                <el-descriptions title="档案分类"></el-descriptions>
                <el-form-item label="档案分类：" prop="archivesBasicDataId" :rules="[{required: true, message: '请选择档案分类', trigger: 'change'}]">
                    <SelectTree v-if="visible"
                                ref="menuParentTree"
                                :props="{
                value: 'id',             // ID字段名
                label: 'archivesBasicName',         // 显示名称
                children: 'children'    // 子级字段名
              }"
                                :data="classifyList"
                                :value="inputForm.archivesBasicDataId"
                                :clearable="true"
                                :accordion="true"
                                :disabled="method!=='add'"
                                @getValue="changeArchives"/>
                </el-form-item>
                <el-form-item label="档案类型：" prop="archivesBasicDataType">
                    <el-input v-model.trim="inputForm.archivesBasicDataType" disabled></el-input>
                </el-form-item>
                <el-descriptions title="档案信息"></el-descriptions>
                <el-form-item class="rowItem" label="题名：" prop="archivesDataName"
                              :rules="[{required: true, message: '请输入题名', trigger: 'blur'}]">
                    <el-input
                            type="textarea"
                            :rows="1"
                            placeholder="请输入题名（限100字）"
                            maxlength="100"
                            v-model.trim="inputForm.archivesDataName">
                    </el-input>
                </el-form-item>
                <el-form-item label="状态：" :rules="[{required: true, message: '请选择状态', trigger: 'change'}]">
                    <el-select class="w100i" v-model="inputForm.warehousingState" :disabled="inputForm.borrowingState == '已借出'">
                        <el-option
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                                v-for="item in warehousingStateOpt">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="借阅状态：" prop="borrowingState" v-show="method != 'add'">
                    <el-input v-model.trim="inputForm.borrowingState" disabled></el-input>
                </el-form-item>
                <el-form-item label="全宗号：" prop="quanzongNumber"
                              :rules="[{required: true, message: '请输入全宗号', trigger: 'blur'}]">
                    <el-input placeholder="请输入全宗号（限50字）"
                              maxlength="50"
                              v-model.trim="inputForm.quanzongNumber">
                    </el-input>
                </el-form-item>
                <el-form-item label="全宗名称：" prop="quanzongName"
                              :rules="[{required: true, message: '请输入全宗名称', trigger: 'blur'}]">
                    <el-input placeholder="请输入全宗名称（限50字）"
                              maxlength="50"
                              v-model.trim="inputForm.quanzongName">
                    </el-input>
                </el-form-item>
                <el-form-item label="档号：" prop="reference"
                              :rules="[{required: true, message: '请输入档号', trigger: 'blur'}]">
                    <el-input placeholder="请输入档号（限50字）"
                              maxlength="50"
                              v-model.trim="inputForm.reference">
                    </el-input>
                </el-form-item>
                <el-form-item :label="item.forName ? item.forName : item.basicName + '：'" :prop="item.code"
                              v-for="(item, index) in config"
                              :key="index">
                    <!--字符-->
                    <el-input :type="item.maxLength && item.maxLength > 100 ? 'textarea': ''"
                              v-model.trim="inputForm[item.code]" :maxlength="item.maxLength || 300"
                              :placeholder="item.forName ? '请输入' + item.forName : '请输入' + item.basicName"
                              clearable
                              v-if="item.archivesBasicTypeCode == 'text' && !item.archivesBasicMultiselectList.length"></el-input>
                    <!--数值、浮点数-->
                    <el-input v-model.trim="inputForm[item.code]" :maxlength="item.archivesBasicTypeCode == 'integer' ? 8 : 11"
                              @input="getNumType(item)"
                              @blur="verifyNum(item)"
                              :placeholder="item.forName ? '请输入' + item.forName : '请输入' + item.basicName"
                              clearable
                              v-if="item.archivesBasicTypeCode == 'integer' || item.archivesBasicTypeCode == 'double'"></el-input>
                    <!--下拉选择-->
                    <el-select class="w100i" v-model="inputForm[item.code]"
                               :multiple="item.archivesBasicMultiselectList[0].selectBy == 1" :collapse-tags="method!=='view'"
                               :placeholder="item.forName ? '请选择' + item.forName : '请选择' + item.basicName" clearable
                               v-if="item.archivesBasicMultiselectList.length">
                        <el-option v-for="(v,i) in item.archivesBasicMultiselectList" :key="i"
                                   :label="v.archivesBasicValue"
                                   :value="v.archivesBasicValue"></el-option>
                    </el-select>
                    <!--时间-->
                    <el-date-picker class="w100i"
                                    v-model="inputForm[item.code]" value-format="yyyy-MM-dd HH:mm:ss"
                                    format="yyyy-MM-dd HH:mm:ss"
                                    type="datetime" clearable placeholder="请选择"
                                    v-if="item.archivesBasicTypeCode == 'date' && item.dataFormat == 'yyyy-MM-dd HH:mm:ss' || item.archivesBasicTypeCode == 'date' && !item.dataFormat">
                    </el-date-picker>
                    <el-date-picker class="w100i"
                                    v-model="inputForm[item.code]" value-format="yyyy-MM-dd HH:mm:ss"
                                    format="yyyy-MM-dd"
                                    type="date" clearable placeholder="请选择"
                                    v-if="item.archivesBasicTypeCode == 'date' && item.dataFormat == 'yyyy-MM-dd'">
                    </el-date-picker>
                    <el-date-picker class="w100i"
                                    v-model="inputForm[item.code]" value-format="yyyy-MM-dd HH:mm:ss"
                                    format="yyyyMMdd"
                                    type="date" clearable placeholder="请选择"
                                    v-if="item.archivesBasicTypeCode == 'date' && item.dataFormat == 'yyyyMMdd'">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="创建人：" v-if="method!=='add'">
                    <el-input v-model.trim="createBy" disabled></el-input>
                </el-form-item>
                <el-form-item label="创建时间：" v-if="method!=='add'">
                    <el-input v-model.trim="createDate" disabled></el-input>
                </el-form-item>
            </el-form>
            <el-descriptions title="档案附件"></el-descriptions>
            <resume-upload class="rowItem" ref="resume" dir="archives" :allowFilesShow="false" :uploadNum="fileNum"
                           :allowFiles="fileFormat"
                           @upFileDate="getFileData" :upFileSize="fileSize" v-if="visible"
                           v-show="fileList.length < 10 && method != 'view'"></resume-upload>
            <div class="tips" v-if="method != 'view'">
                支持上传文档（PDF/DOC/TXT/XLSX）、图片（JPG/PNG）、视频（MP4）、音频（MP3）。单个文档不超过50M，一次最多上传10个附件。
            </div>
            <el-table :data="fileList" size="small" class="table" id="recordTable" v-if="fileList.length">
                <el-table-column prop="fileName" label="题名" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <div>{{scope.row.fileName}}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="fileFormat" label="文件类型">
                    <template slot-scope="scope">
                        <img :src="getFileIcon(scope.row.fileFormat)" class="fileIcon">
                    </template>
                </el-table-column>
                <el-table-column fixed="right" width="180" label="操作">
                    <template slot-scope="scope">
                        <el-button type="text" size="small" @click="preview(scope.row)"
                                   v-if="method == 'add' || hasPermission(scope.row.archivesBasicDataId + '-queryPermissions-1') ||  hasPermission(scope.row.archivesDataId + '-queryPermissions-1')">
                            预览
                        </el-button>
                        <el-button type="text" size="small" v-if="method != 'view'"
                                   @click="delFile(scope.row, scope.$index)">删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="noAccessory" v-if="!fileList.length && !loading && method == 'view'">
                <img src="@/assets/img/noData.png">
                <div>暂无相关数据</div>
            </div>
        </div>
        <div class="text_center p_t2">
            <el-button size="small" @click="visible = false">{{method == 'view' ? '关闭' : '取消'}}</el-button>
            <el-button size="small" type="primary" v-if="method != 'view'" @click="doSubmit()"
                       v-noMoreClick>保存
            </el-button>
        </div>
        <!--预览-->
        <Preview ref="preview"></Preview>
    </el-dialog>
</template>

<script>
    import SelectTree from '@/components/treeSelect/treeSelect.vue'
    import resumeUpload from "@/components/Upload/resumeUpload.vue";
    import Preview from "./preview";

    export default {
        components: {
            SelectTree, resumeUpload, Preview
        },
        data() {
            return {
                id: '',
                title: '',
                visible: false,
                method: '',
                classifyList: [],
                loading: false,
                config: [],
                inputForm: {
                    archivesBasicDataId: '',
                    archivesBasicDataType: '',
                    archivesDataName: '',
                    warehousingState: '在库',
                    borrowingState: '',
                    quanzongNumber: '',
                    quanzongName: '',
                    reference: '',
                },
                rules: {},
                fileNum: 10,
                fileSize: 50,
                fileFormat: ['PDF', 'DOCX', 'XLSX', 'TXT', 'JPG', 'PNG', 'MP4', 'MP3'],
                fileList: [],
                createDate: '',
                createBy: '',
                warehousingStateOpt: [],
            }
        },
        methods: {
            init(row, method, recordTypeId) {
                this.searchTree(recordTypeId)
                this.id = ''
                this.fileList = []
                this.inputForm.archivesBasicDataId = ''
                this.inputForm.archivesBasicDataType = ''
                this.inputForm.archivesDataName = ''
                this.inputForm.warehousingState = '在库'
                this.inputForm.borrowingState = ''
                this.inputForm.quanzongName = ''
                this.inputForm.quanzongNumber = ''
                this.inputForm.reference = ''
                this.borrowingState = ''
                this.rules = {}
                this.method = method
                if (row) {
                    this.id = row.id
                }
                this.warehousingStateOpt = this.$dictUtils.getDictList('warehousing_state').filter(item => item.value != 1)
                if (method === 'add') {
                    this.title = '新增档案信息'
                } else if (method === 'edit') {
                    this.title = '修改档案信息'
                } else if (method === 'view') {
                    this.title = '查看档案信息'
                    this.warehousingStateOpt = this.$dictUtils.getDictList('warehousing_state')
                }
                this.visible = true
                this.$nextTick(() => {
                    this.$refs['resume'].setOldFile([])
                })
                if(recordTypeId) {
                    this.loading = true
                    this.getRecord(recordTypeId)
                }
            },
            // 选择档案分类
            changeArchives(val) {
                this.inputForm.archivesBasicDataId = val
                this.getRecord(val)
            },
            // 浮点数保留两位小数
            verifyNum(item) {
                if (item.archivesBasicTypeCode == "double") {
                    let val = this.inputForm[item.code]
                    let pointNum = val.toString().split(".").length - 1
                    if (val && !this.validate.isPrice(val) && pointNum <= 1) {
                        let num = (val * 1).toFixed(2)
                        this.$set(this.inputForm, item.code, num)
                    }
                }
            },
            // 获取元数据类型设置输入正则
            getNumType(item) {
                let regular = /[^\d]/g
                if (item.archivesBasicTypeCode == "double") {
                    regular = /[^\d.]/g
                }
                this.$set(this.inputForm, item.code, this.inputForm[item.code].replace(regular, ''))
            },
            // 获取数据类型
            getRecord(id) {
                this.$set(this.inputForm, 'archivesBasicDataType', '')
                this.$axios(this.api.record.basicdataGetById + '/' + id).then(data => {
                    if (data.status) {
                        this.config = data.data.archivesBasicDataGroup
                        this.$set(this.inputForm, 'archivesBasicDataType', data.data.archivesBasicDataType)
                        this.config.map(item => {
                            this.$set(this.inputForm, item.code, null)
                            this.$nextTick(() => {
                                this.$refs['inputForm'].clearValidate()
                            })
                            let basicName = item.forName ? item.forName : item.basicName
                            if (item.archivesBasicTypeCode == 'text' && !item.archivesBasicMultiselectList.length) {
                                var checkText = (rule, value, callback) => {
                                    if (item.minLength && value && value.length < item.minLength * 1) {
                                        callback(new Error(basicName + '字数必须大于或等于' + item.minLength));
                                    } else {
                                        callback()
                                    }
                                };
                                this.$set(this.rules, item.code, [
                                    {
                                        required: item.dataRequired == '0' ? true : false,
                                        message: '请输入' + basicName,
                                        trigger: 'blur'
                                    },
                                    {validator: checkText, trigger: 'blur'}
                                ])
                            }
                            if (item.archivesBasicMultiselectList.length) {
                                this.$set(this.rules, item.code, [
                                    {
                                        required: item.dataRequired == '0' ? true : false,
                                        message: '请选择' + basicName,
                                        trigger: this.method == 'view' ? 'blur' : 'change',
                                    },
                                ])
                            }
                            if (item.archivesBasicTypeCode == 'integer' || item.archivesBasicTypeCode == 'double') {
                                var checkInteger = (rule, value, callback) => {
                                    if (item.archivesBasicTypeCode == 'double' && value && !this.validate.isPrice(value)) {
                                        callback(new Error('请输入正确的' + basicName));
                                    } else if (value && value * 1 > 99999999) {
                                        callback(new Error(basicName + '不能大于99999999'));
                                    } else if (item.minLength && value && value * 1 < item.minLength * 1) {
                                        callback(new Error(basicName + '必须大于或等于' + item.minLength));
                                    } else if (item.maxLength && value && value * 1 > item.maxLength * 1) {
                                        callback(new Error(basicName + '必须小于或等于' + item.maxLength));
                                    } else {
                                        callback();
                                    }
                                };
                                this.$set(this.rules, item.code, [
                                    {
                                        required: item.dataRequired == '0' ? true : false,
                                        message: '请输入' + basicName,
                                        trigger: 'blur'
                                    },
                                    {validator: checkInteger, trigger: 'blur'}
                                ])
                            }
                            if (item.archivesBasicTypeCode == 'date') {
                                var checkDate = (rule, value, callback) => {
                                    const regDate = /^(\d{4})(\d{2})(\d{2})$/;
                                    if (item.minLength && value && new Date(value.replace(regDate, "$1-$2-$3")) < new Date(item.minLength.replace(regDate, "$1-$2-$3"))) {
                                        callback(new Error(basicName + '必须大于或等于' + item.minLength));
                                    } else if (item.maxLength && value && new Date(value.replace(regDate, "$1-$2-$3")) > new Date(item.maxLength.replace(regDate, "$1-$2-$3"))) {
                                        callback(new Error(basicName + '必须小于或等于' + item.maxLength));
                                    } else {
                                        callback();
                                    }
                                };
                                this.$set(this.rules, item.code, [
                                    {
                                        required: item.dataRequired == '0' ? true : false,
                                        message: '请选择' + basicName,
                                        trigger: this.method == 'view' ? 'blur' : 'change'
                                    },
                                    {validator: checkDate, trigger: this.method == 'view' ? 'blur' : 'change'}
                                ])
                            }
                            this.loading = false
                        })
                        if (this.method != 'add') {
                            this.getInfo(id)
                        }
                    }
                })
            },
            // 查询树状数据
            searchTree(archivesBasicDataId) {
                this.$axios(this.api.record.selectArchivesBasicData).then((res) => {
                    if (res.status) {
                        this.classifyList = res.data
                        this.$nextTick(() => {
                            if(archivesBasicDataId) {
                                this.$set(this.inputForm, "archivesBasicDataId", archivesBasicDataId)
                            }
                        })
                    }
                })
            },
            // 查询详情数据
            getInfo(id) {
                this.loading = true
                this.$axios(this.api.record.getById, {
                    archivesBasicDataId: id,
                    id: this.id
                }, 'get').then((res) => {
                    if (res.status) {
                        this.loading = false
                        this.createDate = res.data.createDate
                        this.createBy = res.data.createBy
                        this.inputForm = res.data.archivesData
                        this.$set(this.inputForm, "archivesBasicDataId", res.data.archivesBasicDataId)
                        this.$set(this.inputForm, "archivesBasicDataType", res.data.archivesBasicDataType)
                        this.$set(this.inputForm, "archivesDataName", res.data.archivesDataName)
                        this.$set(this.inputForm, "warehousingState", res.data.warehousingState)
                        this.$set(this.inputForm, "borrowingState", res.data.borrowingState)
                        this.$set(this.inputForm, "quanzongNumber", res.data.quanzongNumber)
                        this.$set(this.inputForm, "quanzongName", res.data.quanzongName)
                        this.$set(this.inputForm, "reference", res.data.reference)
                        this.fileList = res.data.archivesFileList
                        this.$refs['resume'].setOldFile(this.fileList)
                        for (let key in this.inputForm) {
                            this.config.map(item => {
                                if (item.code == key && item.archivesBasicMultiselectList.length && item.archivesBasicMultiselectList[0].selectBy == 1) {
                                    if (this.inputForm[key]) {
                                        this.inputForm[key] = this.inputForm[key].split(',')
                                    } else {
                                        this.inputForm[key] = []
                                    }
                                }
                            })
                        }
                    }
                })
            },
            // 获取上传的文件
            getFileData(data) {
                this.fileList = []
                data.map(item => {
                    if (item.upDate) {
                        this.fileList.push({
                            absolutePath: item.upDate.filePath,
                            accessPath: item.upDate.netUrl,
                            fileFormat: item.upDate.fileFormat,
                            fileName: item.upDate.fileName,
                            fileSize: item.upDate.fileSize,
                            fileID: item.id,
                        })
                    } else {
                        this.fileList.push({
                            absolutePath: item.absolutePath,
                            accessPath: item.accessPath,
                            fileFormat: item.fileFormat,
                            fileName: item.fileName,
                            fileSize: item.fileSize,
                            fileID: item.id,
                        })
                    }
                })
            },
            // 删除文件
            delFile(row, index) {
                this.$confirm(`确定删除所选项吗?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.fileList.splice(index, 1);
                    this.$refs["resume"].deleteFile(row.fileID ? row.fileID : row.id)
                })
            },
            // 预览
            preview(item) {
                if (this.method == 'add' || !item.id) {
                    this.$refs.preview.init(item)
                } else {
                    this.$axios(this.api.record.utilizeQuery, {archivesFileId: item.id}, 'get').then(res => {
                        if (res.status) {
                            let data = JSON.parse(JSON.stringify(item))
                            data.accessPath = res.data
                            this.$refs.preview.init(data)
                        } else {
                            this.$message.error('预览失败');
                        }
                    })
                }
            },
            // 提交
            doSubmit() {
                this.$refs['inputForm'].validate((valid) => {
                    if (valid) {
                        let param = JSON.parse(JSON.stringify(this.inputForm))
                        for (let key in param) {
                            if (Array.isArray(param[key])) {
                                param[key] = param[key].join(",")
                            }
                        }
                        delete param.archivesBasicDataType
                        delete param.archivesDataName
                        delete param.quanzongNumber
                        delete param.quanzongName
                        delete param.reference
                        this.loading = true
                        this.$axios(this.api.record.collectSave, {
                            warehousingState: this.inputForm.warehousingState,
                            archivesDataSource: '0',
                            archivesBasicDataId: this.inputForm.archivesBasicDataId,
                            archivesData: param,
                            archivesDataName: this.inputForm.archivesDataName,
                            quanzongNumber: this.inputForm.quanzongNumber,
                            quanzongName: this.inputForm.quanzongName,
                            reference: this.inputForm.reference,
                            archivesFile: this.fileList,
                            id: this.id,
                        }, 'post').then(data => {
                            this.loading = false
                            if (data && data.status) {
                                this.$message.success(data.msg)
                                this.visible = false
                                this.$emit('refreshDataList')
                            } else {
                                this.$message.error(data.msg)
                            }
                        })
                    }
                })
            },
        },
    }
</script>

<style scoped>
    .rowItem {
        width: 97% !important;
    }
</style>
